const theme = {
	colors: {
		pryOrange: '#F5915A',
		lineGray: '#BEBEBE',
		bgGray: '#F3F3F3',
		warning: '#E96D6D',
		brandFbBlue: '#2374f2',
		textGray: '#606060',
	},
	fonts: {
		heading: '"Noto Sans TC", sans-serif',
		body: '"Noto Sans TC", sans-serif',
	},
	components: {
		Button: {
			baseStyle: {
				_focus: {
					boxShadow: 'none',
				},
			},
			variants: {
				solid: {
					bg: '#F5915A',
					_hover: {
						bg: '#ED8936',
					},
				},
			},
		},
		Input: {
			variants: {
				outline: {
					field: {
						border: '0px',
						_focus: {
							borderColor: '#F5915A',
							boxShadow: 'none',
						},
						_hover: {
							borderColor: '#F5915A',
						},
					},
				},
			},
		},
	},
	zIndices: {
		hide: -1,
		auto: 'auto',
		base: 0,
		docked: 10,
		dropdown: 1000,
		sticky: 1100,
		banner: 1200,
		overlay: 1300,
		modal: 1400,
		popover: 1500,
		skipLink: 1600,
		toast: 1700,
		tooltip: 1800,
	},
};

export default theme;
