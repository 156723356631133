import React from 'react';
import Script from 'next/script';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { config } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';
// import { appWithTranslation } from 'next-i18next';
// import nextI18nextConfig from 'next-i18next.config';
import { SessionProvider } from 'next-auth/react';
import myTheme from '../components/theme';
import Provider from '../contexts/authContext';
import 'shepherd.js/dist/css/shepherd.css';
import '../styles/globals.css';

config.autoAddCss = false;
const theme = extendTheme(myTheme);

function MyApp({ Component, pageProps: { session, ...pageProps } }) {
	return (
		/* <SessionProvider session={session}>
			<ChakraProvider theme={theme}>
				<Component {...pageProps} />
			</ChakraProvider>
		</SessionProvider> */
		<SessionProvider session={session}>
			<Provider>
				<ChakraProvider theme={theme}>
					<Script
						src="https://www.googletagmanager.com/gtag/js?id=G-6FGNZZDQP6"
						strategy="afterInteractive"
					/>
					<Script
						id="google-analytics"
						strategy="afterInteractive"
					>
						{`
							window.dataLayer = window.dataLayer || [];
							function gtag(){dataLayer.push(arguments);}
							gtag('js', new Date());

							gtag('config', 'G-6FGNZZDQP6');
						`}
					</Script>
					<Component {...pageProps} />
				</ChakraProvider>
			</Provider>
		</SessionProvider>
	);
}

// export default appWithTranslation(MyApp, nextI18nextConfig);
export default MyApp;
