import React, { useState, useEffect, createContext } from 'react';
import dynamic from 'next/dynamic';
import dayjs from 'dayjs';
import axios from 'axios';
import { useSessionStorage } from 'react-use';
// import * as Sentry from '@sentry/nextjs';

const ContextStore = createContext({});

const parseCookie = async () => {
	try {
		const { data } = await axios.get(`${process.env.NEXT_PUBLIC_AUTH_API_PATH}/api/v1/user/me`, {
			withCredentials: true,
		});
		return data;
	} catch (e) {
		return {};
	}
};

function Provider({ children }) {
	const [us, setUs] = useSessionStorage('userState');
	const [userState, setUserState] = useState(us);

	useEffect(() => {
		parseCookie().then((data) => {
			setUs(data);
			setUserState(data);
		});
	}, []);

	const [rangeState, _setRangeState] = useState({ start: dayjs('2011-01-01').format('YYYY-MM-DD'), end: dayjs().format('YYYY-MM-DD'), unit: 'y' });
	const logout = () => {
		axios.post(`${process.env.NEXT_PUBLIC_AUTH_API_PATH}/api/v1/user/logout`, undefined, {
			withCredentials: true,
		});
	};
	const setRangeState = (data) => {
		_setRangeState({ ...rangeState, ...data });
	};
	const refresh = async () => {
		const data = await parseCookie();
		setUs(data);
		setUserState(data);
	};

	/*
	const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
	if (SENTRY_DSN) {
		const {
			_id, user, mail, ...state
		} = userState;
		Sentry.setUser({
			id: _id,
			username: user,
			...state,
		});
	}
    */

	return (
		<ContextStore.Provider
			// eslint-disable-next-line react/jsx-no-constructed-context-values
			value={{
				userState,
				setUserState,
				logout,
				refresh,
				rangeState,
				setRangeState,
			}}
		>
			{ children }
		</ContextStore.Provider>
	);
}

export default dynamic(() => Promise.resolve(Provider), { ssr: false });
export {
	ContextStore,
	parseCookie,
};
